@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
}

::selection {
  color: rgb(30, 144, 255);
}

div.ag-root {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.h_rem {
  height: calc(100% - (30px));
}

.h_specific {
  height: calc(100% - (6%));
}

.w_collapsed {
  width: calc(100vw - (80px));
}
.w_side {
  width: calc(100vw - (220px));
}

.m_collapsed {
  margin-left: 80px;
}

.m_side {
  margin-left: 220px;
}

.h_nav {
  height: calc(60px);
}

.h_table {
  height: calc(100vh - (212px));
}

.h_supp {
  height: calc(100vh - (185px));
}

.h_table_opt {
  height: calc(100vh - (180px));
}

.h_table_opt2 {
  height: calc(100vh - (128px));
}

.h_table_supp {
  height: calc(100vh - (136px));
}

.h_sidebar {
  height: calc(100vh - (180.8px));
}

.h_scale {
  height: calc(100vh - (260px));
}

.h_das_table {
  height: calc(100vh - (168px));
}

.h_das_table1 {
  height: calc(100vh - (112px));
}

.h_das_table2 {
  height: calc(100vh - (84px));
}

.h_screen {
  height: calc(100vh - (60px));
}
.h_view {
  height: calc(100vh - (60px));
}

.h_dasView {
  height: calc(100vh - (48px));
}

.h_modal {
  height: calc(100vh - (74px));
}

.h_content {
  height: calc(100vh - (120px));
}

.w_modal {
  width: calc(100vw - (250px));
}

.w_content {
  width: calc(100vh - (100px));
}

.ag-cell-focus,
.ag-cell {
  border: none !important;
}
.react-switch {
  vertical-align: middle;
}

.main-wrapper {
  height: 60vh;
  margin: 10px 50px;
  filter: drop-shadow(-1px 5px 3px #ccc);
}

.gm-style-iw > button {
  display: none !important;
}

.search {
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 350px;
  z-index: 10;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.search input {
  paddingleft: 0.5rem;
  font-size: 1.1rem;
  width: 100%;
}

.locate {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  z-index: 10;
}
.locate img {
  width: 30px;
  cursor: pointer;
}
.disabled {
  background: transparent;
  box-shadow: none;
  border: none;
}
.h_camera {
  height: calc(100vh - (92px));
}

.h_open {
  height: calc(100vh - (270px));
}

.con {
  height: 130px;
  width: 155px;
}
.h_group {
  height: calc(100vh - (180px));
}
.list_view_grey_style {
  background-color: red;
}
.list_font_style {
  font-size: large;
}
.cell_border_black {
  border: 1px solid black;
  border-radius: 20px;
  margin-left: 5px;
}
.bg_grey {
  background-color: rgb(226, 223, 223);
}
.hr_style {
  border-top: 2px solid black;
  margin: 10px;
}
.button-style {
  border: 1px solid black;
}
.justify-left {
  justify-content: left;
}
.ml-25rem {
  margin-left: 25rem;
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}
.ml-35rem {
  margin-left: 35rem;
}
.ml-40rem {
  margin-left: 40rem;
}
.border_black {
  border: 1px solid rgb(175, 174, 174);
  border-radius: 5px;
}
.cell_style {
  width: 50%;
}
.table_style {
  width: 100%;
  margin-bottom: 10px;
}
