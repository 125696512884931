/* Add this CSS to your styles */
.slick-prev,
.slick-next {
  background-color: #000; /* Set the background color to black */
  color: #fff; /* Set the text color to white or any contrasting color */
}

/* .slick-prev:hover, */
/* .slick-next:hover { */
  /* background-color: #333 !important; Change the button color on hover if desired */
/* } */
