/* Hide the scrollbars by default */
.ag-theme-alpine .ag-body-horizontal-scroll-viewport::-webkit-scrollbar,
.ag-theme-alpine .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* Show and style the horizontal scrollbar on hover */
.ag-theme-alpine:hover .ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

.ag-theme-alpine:hover .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-thumb {
  background-color: #E0E0E0; /* Light grey color for the thumb */
  border-radius: 3px;
}

.ag-theme-alpine:hover .ag-body-horizontal-scroll-viewport::-webkit-scrollbar-track {
  background-color: #F5F5F5; /* Light grey color for the track */
}

/* Vendor-specific prefixes for Firefox */
.ag-theme-alpine:hover .ag-body-horizontal-scroll-viewport {
  scrollbar-width: thin;
  scrollbar-color: #E0E0E0 #F5F5F5;
}

/* Show and style the vertical scrollbar on hover */
.ag-theme-alpine:hover .ag-body-vertical-scroll-viewport::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

.ag-theme-alpine:hover .ag-body-vertical-scroll-viewport::-webkit-scrollbar-thumb {
  background-color: #E0E0E0; /* Light grey color for the thumb */
  border-radius: 3px;
}

.ag-theme-alpine:hover .ag-body-vertical-scroll-viewport::-webkit-scrollbar-track {
  background-color: #F5F5F5; /* Light grey color for the track */
}

/* Vendor-specific prefixes for Firefox */
.ag-theme-alpine:hover .ag-body-vertical-scroll-viewport {
  scrollbar-width: thin;
  scrollbar-color: #E0E0E0 #F5F5F5;
}
